import React from "react"
import { styled } from "../components/mediaCSS"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContentHolder = styled.div``
const Content = styled.div``

export default () => {
  return (
    <Layout>
      <SEO title="About Us" />
      <ContentHolder>
        <Content>
          <h1>SMS Messaging Guidelines</h1>

          <h2>1. Purpose</h2>
          <p>
            These SMS Messaging Guidelines are established to ensure compliance
            with applicable laws, regulations, and best practices when sending
            SMS messages on behalf of Mama's Pizza.
          </p>

          <h2>2. Consent</h2>
          <ul>
            <li>
              We will only send SMS messages to recipients who have provided
              explicit consent to receive them.
            </li>
            <li>
              Consent records will be maintained and regularly audited for
              compliance.
            </li>
          </ul>

          <h2>3. Message Content</h2>
          <ul>
            <li>SMS messages will contain clear and concise information.</li>
            <li>Messages will identify the sender.</li>
            <li>
              We will avoid using misleading or deceptive content in SMS
              messages.
            </li>
          </ul>

          <h2>4. Frequency</h2>
          <ul>
            <li>SMS messages will be sent at a reasonable frequency.</li>
            <li>
              Recipients will have the option to opt-out at any time, and their
              preferences will be respected.
            </li>
          </ul>

          <h2>5. Compliance with Regulations</h2>
          <ul>
            <li>
              All SMS messages will comply with relevant data protection and
              privacy regulations, such as GDPR, CAN-SPAM, and TCPA.
            </li>
            <li>
              We will maintain records of all SMS communications as required by
              law.
            </li>
          </ul>

          <h2>6. Opt-Out Mechanism</h2>
          <ul>
            <li>
              An opt-out mechanism, such as replying with "STOP" or a similar
              keyword, will be provided in each SMS message.
            </li>
            <li>
              Opt-out requests will be promptly honored, and recipients will not
              receive further messages.
            </li>
          </ul>

          <h2>7. Data Security</h2>
          <ul>
            <li>We will protect recipient data and maintain it securely.</li>
            <li>
              Any third-party SMS service providers will adhere to strict data
              security standards.
            </li>
          </ul>

          <h2>8. Message Delivery Times</h2>
          <ul>
            <li>
              SMS messages will be sent during reasonable hours and in
              accordance with local regulations.
            </li>
          </ul>

          <h2>9. Training and Compliance</h2>
          <ul>
            <li>
              Employees involved in sending SMS messages will receive training
              on these guidelines and relevant regulations.
            </li>
            <li>
              Regular audits and assessments will be conducted to ensure
              compliance.
            </li>
          </ul>

          <h2>10. Reporting and Feedback</h2>
          <ul>
            <li>
              Recipients will be encouraged to provide feedback or report issues
              with SMS messages.
            </li>
            <li>
              Complaints will be addressed promptly and investigated as needed.
            </li>
          </ul>

          <h2>11. Review and Updates</h2>
          <ul>
            <li>
              These guidelines will be reviewed periodically to ensure ongoing
              compliance with changing regulations and industry best practices.
            </li>
          </ul>

          <p>
            By adhering to these SMS Messaging Guidelines, Mama's Pizza is
            committed to maintaining the highest standards of compliance and
            respecting the privacy and preferences of SMS recipients.
          </p>

          <p>Mama&#39;s Pizza 817-870-2571</p>
        </Content>
      </ContentHolder>
    </Layout>
  )
}
